<template>
  <div class="p-1">
    <div class="mb-2 flex justify-between items-center">
      <a-button @click="$router.back()">
        {{ lang.common.back }}
      </a-button>
      <a-dropdown right v-if="$route.name !== 'section_students_courses_notes'">
        <template #menu>
          <a-dropdown-item :to="{ name: 'section_students', params: { ...$route.params } }" v-if="$route.name === 'section'">
            Ver estudiantes
          </a-dropdown-item>
          <a-dropdown-item v-if="$route.name === 'section'" :href="`${url}/api/sections/${$route.params.section_id}/reports/students`" download>
            Descargar estudiantes
          </a-dropdown-item>
          <a-dropdown-item :to="{ name: 'section_students_courses_notes' }">
            Ver carpetón
          </a-dropdown-item>
          <a-dropdown-item :to="{ name: 'create_section', query: { update: $route.params.section_id } }">
            Actualizar
          </a-dropdown-item>
        </template>
      </a-dropdown>
      <a-button v-else @click="printDiv()">
        Imprimir
      </a-button>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    url: process.env.VUE_APP_PRODUCTION_API_URL
  }),
  computed: mapGetters({
    lang: 'lang'
  }),
  methods: {
    ...mapActions({
      fetchSection: 'sections/fetch',
      fetchTeachers: 'teachers/index',
    }),
    downloadStudents () {
      this.$repository.sections
        .sectionReport(this.$route.params.section_id)
    },
    printDiv() {
      // let printContents = document.getElementById('schedule').innerHTML;
      let win = window.open('', 'PRINT', 'height=1080,width=1920');

      win.document.write('<html><head><title>' + document.title  + '</title>');
      win.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.8.10/tailwind.min.css"/>');
      win.document.write('</head><style>body {-webkit-print-color-adjust: exact;padding-top: 15px;font-size: 12px !important;}</style><body >');
      // win.document.write('<h1>' + document.title  + '</h1>');
      win.document.write(document.querySelector('#studentsCoursesNotes').innerHTML);
      win.document.write('</body></html>');

      win.document.close(); // necessary for IE >= 10
      win.focus(); // necessary for IE >= 10*/

      setTimeout(function () {
        win.print();
      }, 4000)
    }
  },
  mounted() {
    Promise.all([
      this.fetchTeachers({ limit: 0 }),
      this.fetchSection(this.$route.params.section_id)
    ])
  }
}
</script>
